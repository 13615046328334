<template>
  <ion-page id="re-page">
    <ion-content :fullscreen="true">
      <topbar :title="$t('register.Registration')" :showBack="false" :noboder="true"></topbar>
      <div class="pc-box">
        <div class="pc-min-content">
          <div class="content register-content">
            <img src="../assets/login.jpg" style="width: 100%" />
            <div class="me-content">
              <div :style="$store.state.isPC ? '' : 'padding-top: 30px'">
                <div class="main-color font-16 Medium" style="line-height: 24px" :class="{ 'font-20': $store.state.isPC, 'letter-ios': $store.state.deviceType == 1 }">
                  {{ $t("me.RorL") }}
                </div>
                <!-- <div class="main-color font-16 loginTip">
            Don’t have an account?
            <u @click="goRegistration">Sign up</u>
          </div> -->
                <ion-button class="login-btn font-b" @click="goRegistration">{{ $t("me.Register") }}</ion-button>
                <ion-button class="login-btn font-b" style="margin-top: 20px" @click="goLogin">{{ $t("me.Login") }}</ion-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
// import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';

export default {
  name: "registerPage",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, IonButton, topbar },
  data() {
    return {
      logo: require("@/assets/logo.png"),
    };
  },
  methods: {
    goRegistration() {
      this.$store.state.form.registrationBack = "/main/me";
      this.$store.state.firstPage = 1;
      this.$router.push({
        path: "/signup",
      });
      this.sendGAEvent("Click", "Register", "Register");
    },
    goLogin() {
      this.$store.state.form.registrationBack = "/main/me";
      this.$store.state.firstPage = 2;
      this.$router.push({
        path: "/login",
      });
      this.sendGAEvent("Click", "Register", "Log in");
    },
  },
  ionViewWillEnter() {
    if (this.$store.state.form.token) {
      this.$router.replace({
        path: "/main/landing",
      });
      return;
    }
    this.$store.state.exitApp = false;
    //   clean userInfo
    this.$store.state.form.userInfo = {};
    this.$store.state.userInfo.firstName = "";
    this.$store.state.userInfo.lastName = "";
    this.$store.state.userInfo.phone = "";
    this.$store.state.userInfo.email = "";
    this.$store.state.userInfo.isPaperReceipt = false;
    this.$store.state.verifyUserInfo = null;
    this.sendGAPageView("Registration");
  },
};
</script>
